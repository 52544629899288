// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Modal,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Select,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Popover,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  RadioGroup,
  Radio,
  Stack,
  Slider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
} from '@chakra-ui/react';

import { db } from '../../../../firebase';
import {
    query,
    getDocs,
    collection,
    where,
    addDoc,
  } from 'firebase/firestore';

  import {
    billingData,
    invoicesData,
    newestTransactions,
    olderTransactions,
  } from "variables/general";

// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import TablesTableRow from 'components/Tables/TablesTableRow';
import CardHeader from 'components/Card/CardHeader';
import PlanManager from './PlanManager';
import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import ProjectCard from './ProjectCard';
import { connect, useSelector } from 'react-redux';

const ExistingPlans = ({ title, description }) => {
  const auth = useSelector(state => state.auth);
  const [plans, setPlans] = useState([]);

  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');

  return(
    // <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
    //   <CardHeader p="6px 0px 22px 0px">
    //     <Text fontSize="xl" color={textColor} fontWeight="bold">
    //       {title}
    //     </Text>
    //   </CardHeader>
    //   <CardBody>
    //     <Table variant="simple" color={textColor}>
    //       <Tbody>
    //         {plans &&
    //           plans.map(plan => {
    //             console.log(plan);
    //             return (
    //               <TablesTableRow
    //                 key={`${plan.id}`}
    //                 name={plan.name}
    //                 // logo={member.profilePic}
    //                 // email={member.email}
    //                 // subdomain={member.membership || 'None'}
    //                 // domain={row.domain}
    //                 // status={member.status || 'Inactive'}
    //                 // date={member.dateAdded || 'n/a'}
    //               />
    //             );
    //           })}
    //       </Tbody>
    //     </Table>
    //   </CardBody>
    // </Card>
    <PlanManager data={billingData} />
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ExistingPlans);
