import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// Remove GoogleAuthProvider if not using Google Auth
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from 'firebase/firestore';
import {useDispatch} from 'react-redux';
import {login} from '../reducers';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Remove if not using Google Auth
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     const res = await signInWithEmailAndPassword(auth, email, password);
//     const user = res.user
//     const q = query(collection(db, 'clients'), where('uid', '==', user.uid));
//     const docs = await getDocs(q);
//     const data = docs.docs[0].data()
//     dispatch(login(data));
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// const registerWithEmailAndPassword = async (businessName, name, email, password, phone) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, 'clients'), {
//       uid: user.uid,
//       'businessName': businessName,
//       name,
//       authProvider: 'local',
//       email,
//       'phone': phone
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// const createMember = async (firstName, lastName, email, user) => {
//   try {
//     const today = new Date().toISOString().slice(0, 10)
//     await addDoc(collection(db, `members`), {
//       "id" : null,
//       "fullname": firstName + " " + lastName,
//       "appIdentifier" : null,
//       "dateAdded" : today,
//       "profilePic" : "https://i.imgur.com/WMy7Wid.png",
//       "membership" : null,
//       email,
//       "onboarded": false,
//       "steps": null,
//       "weight": null,
//       "height": null,
//       "dob": null,
//       "status" : "Inactive",
//       clientId: user.uid,
//       "isRegistered": false,
//       "memberId": null,
//       // "clientName": null,
//       // "clientOwnerName": null,
//       // "clientOwnerEmail": null
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const sendPasswordReset = async email => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  // logInWithEmailAndPassword,
  // registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  // createMember,
};
