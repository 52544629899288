// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import { AddIcon, EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import MembersRow from './MembersRow';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import {
  query,
  collection,
  where,
  addDoc,
  doc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

const Members = ({ title, captions, data }) => {
  const authentication = useSelector(state => state.auth);
  const textColor = useColorModeValue('gray.700', 'white');
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [members, setMembers] = useState([]);
  const [displaySkeleton, setDisplaySkeleton] = useState('block');
  const [user, loading] = useAuthState(auth);
  const [firstName, setFirstName] = useState('');
  const [noMembersIndicator, setNoMembersIndicator] = useState('none');
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();
  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
  };

  const [lastName, setLastName] = useState('');
  const handleLastNameChange = event => {
    setLastName(event.target.value);
  };

  const [email, setEmail] = useState('');
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const onSave = () => {
    onClose();
    createMember();
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const deleteMember = async id => {
    const docRef = await doc(db, 'members', id);
    deleteDoc(docRef)
      .then(() => {
        console.log('Member has been deleted successfully.');
        onCloseEdit();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const createMember = async () => {
    try {
      const today = new Date().toISOString().slice(0, 10);
      const guid = 'MBR-' + uuidv4();
      await addDoc(collection(db, `members`), {
        id: null,
        clientGuid: authentication.user.guid,
        guid,
        fullname: firstName + ' ' + lastName,
        appIdentifier: null,
        dateAdded: today,
        profilePic: 'https://i.imgur.com/WMy7Wid.png',
        plan: 'None',
        email,
        onboarded: false,
        steps: null,
        weight: null,
        height: null,
        dob: null,
        status: 'Inactive',
        clientId: user.uid,
        isRegistered: false,
        memberId: null,
        clientName: authentication.user.businessName,
        clientOwnerName: authentication.user.name,
        clientOwnerEmail: authentication.user.email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://vuyio.com/auth/login',
    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.vuyio',
    },
    android: {
      packageName: 'com.vuyio',
      installApp: true,
      minimumVersion: '12',
    },
    dynamicLinkDomain: 'vuyio.page.link',
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchMembers();
    }
  }, [user, loading]);

  const fetchMembers = async () => {
    const q = query(
      collection(db, 'members'),
      where('clientGuid', '==', authentication.user.guid)
    );
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let membersArr = [];
      querySnapshot.forEach(doc => {
        membersArr.push({ ...doc.data(), id: doc.id });
      });
      setMembers(membersArr);
      if (membersArr.length > 0) {
        setNoMembersIndicator('none');
      } else {
        setNoMembersIndicator('block');
      }
    });
    setDisplaySkeleton('none');

    return () => unsubscribe();
  };

  return (
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <Flex marginBottom="10px">
        <Button onClick={onOpen} leftIcon={<AddIcon />}>
          Add new member
        </Button>
      </Flex>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {captions.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? '0px' : null}>
                    {caption}
                    <Skeleton
                      display={displaySkeleton}
                      marginTop="10px"
                      height="40px"
                    />
                    <Skeleton
                      display={displaySkeleton}
                      marginTop="10px"
                      height="40px"
                    />
                    <Skeleton
                      display={displaySkeleton}
                      marginTop="10px"
                      height="40px"
                    />
                    <Skeleton
                      display={displaySkeleton}
                      marginTop="10px"
                      height="40px"
                    />
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {members &&
              members.map(member => {
                return (
                  <MembersRow
                    key={`${member.id}`}
                    name={member.fullname}
                    photo={member.profilePic}
                    email={member.email}
                    member={member}
                    status={member.status || 'Inactive'}
                    date={member.dateAdded || 'n/a'}
                  />
                );
              })}
          </Tbody>
        </Table>
      </CardBody>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new member account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>First name</FormLabel>
              <Input
                ref={initialRef}
                placeholder="First name"
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last name</FormLabel>
              <Input
                placeholder="Last name"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>E-mail Address</FormLabel>
              <Input
                placeholder="E-mail Address"
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => onSave()} colorScheme="blue" mr={3}>
              Create member account
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Text
        display={noMembersIndicator}
        fontSize="md"
        color="gray.500"
        fontWeight="400"
        mb="20px"
      >
        {' '}
        You haven't created any members yet. Click the button above to get
        started.{' '}
      </Text>
    </Card>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Members);
