// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Select,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Popover,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  RadioGroup,
  Radio,
  Stack,
  Slider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
} from '@chakra-ui/react';

import { addDoc, collection } from 'firebase/firestore';

import { db } from '../../../../firebase';
import { v4 as uuidv4 } from 'uuid';

// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import ProjectCard from './ProjectCard';
import { connect, useSelector } from 'react-redux';
import { Separator } from '../../../../components/Separator/Separator';

const Plans = ({ title, description }) => {
  const auth = useSelector(state => state.auth);
  const initialRef = React.useRef(null);
  const [selectValue, setSelectValue] = React.useState('Recurring');
  const [recurrenceValue, setRecurrenceValue] = React.useState('Monthly');
  const [prepaidRecurrenceValue, setPrepaidRecurrenceValue] = React.useState(
    'Days'
  );
  const [sliderValue, setSliderValue] = React.useState(30);
  const [validFor, setValidFor] = React.useState(7);
  const handleSliderChange = value => setSliderValue(value);
  const handleValidForChange = value => setValidFor(value);
  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');
  const [price, setPrice] = React.useState('35.99');
  const {
    isOpen: isRecurringModalOpen,
    onOpen: onRecurringModalOpen,
    onClose: onRecurringModalClose,
  } = useDisclosure();
  const {
    isOpen: isPackModalOpen,
    onOpen: onPackModalOpen,
    onClose: onPackModalClose,
  } = useDisclosure();
  const {
    isOpen: isPrepaidModalOpen,
    onOpen: onPrepaidModalOpen,
    onClose: onPrepaidModalClose,
  } = useDisclosure();
  const initialFocusRef = React.useRef();

  const [planName, setPlanName] = useState('');
  const handlePlanNameChange = event => {
    setPlanName(event.target.value);
  };

  const openModal = () => {
    if (selectValue == 'Recurring') {
      setPlanName("")
      onRecurringModalOpen();
    }
    if (selectValue == 'Pack') {
      setPlanName("")
      onPackModalOpen();
    }
    if (selectValue == 'Prepaid') {
      setPlanName("")
      onPrepaidModalOpen();
    }
  };

  async function createPlan() {
    try {
      const today = new Date().toISOString().slice(0, 10);
      const guid = 'PLN-' + uuidv4();
      await addDoc(collection(db, `plans`), {
        createdAt: today,
        guid,
        clientGuid: auth.user.guid,
        clientId: auth.user.uid,
        name: planName,
        type: selectValue,
        price: price,
        recurrence: recurrenceValue,
        packSize: sliderValue,
        validFor: validFor,
        validForType: prepaidRecurrenceValue,
      });
      onRecurringModalClose();
      onPrepaidModalClose();
      onPackModalClose();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Card p="16px" my="24px">
      <CardHeader p="12px 5px" mb="12px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            {title}
          </Text>
          <Text fontSize="sm" color="gray.500" fontWeight="400">
            {description}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody px="5px" marginBottom="20px" marginLeft="20px">
        <Grid
          templateColumns={{ sm: '1fr', md: '1fr 1fr', xl: 'repeat(4, 1fr)' }}
          templateRows={{ sm: '1fr 1fr 1fr auto', md: '1fr 1fr', xl: '1fr' }}
          gap="24px"
        >
          <ProjectCard
            category={"Plans"}
            description={
              'Members must be enrolled in a plan to be billed. Creating a plan is easy, click on the button to get started.'
            }
            avatars={[]}
          />

          <Popover
            initialFocusRef={initialFocusRef}
            placement="right"
            closeOnBlur={true}
          >
            <PopoverTrigger>
              <Button
                p="0px"
                bg="transparent"
                color="gray.500"
                border="1px solid lightgray"
                borderRadius="15px"
                minHeight={{ sm: '200px', md: '100%' }}
                maxWidth={{sm: '250px', md: '250px', lg: '250px', xl: '250px'}}
              >
                <Flex direction="column" justifyContent="center" align="center">
                  <Icon as={FaPlus} fontSize="lg" mb="12px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Create a New Plan
                  </Text>
                </Flex>
              </Button>
            </PopoverTrigger>
            <PopoverContent color="white" bg="gray.700" borderColor="blue.800">
              <PopoverHeader pt={4} fontWeight="bold" border="0">
                Select plan type
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <FormControl>
                  <FormLabel>Plan type</FormLabel>
                  <Select
                    mt={4}
                    value={selectValue}
                    onChange={e => setSelectValue(e.target.value)}
                  >
                    <option value="Recurring">Recurring</option>
                    <option value="Pack">Pack</option>
                    <option value="Prepaid">Prepaid</option>
                  </Select>
                </FormControl>
              </PopoverBody>
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Box fontSize="md"></Box>
                <ButtonGroup size="sm">
                  <Button onClick={() => openModal()} color="white">
                    Next
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Grid>
      </CardBody>

      {/* Recurring Modal */}
      <Modal
        size={'xl'}
        isOpen={isRecurringModalOpen}
        onClose={onRecurringModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create recurring plan</ModalHeader>
          <Separator />
          <ModalCloseButton />
          <ModalBody>
            <FormControl marginBottom="10px" marginTop="20px">
              <FormLabel>Plan name</FormLabel>
              <Input
                ref={initialRef}
                placeholder="Plan name"
                value={planName}
                onChange={handlePlanNameChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Price</FormLabel>
              <NumberInput
                precision={2}
                onChange={valueString => setPrice(parse(valueString))}
                value={format(price)}
                max={1000}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl marginTop="20px" marginBottom="50px">
              <RadioGroup onChange={setRecurrenceValue} value={recurrenceValue}>
                <Stack direction="row">
                  <Radio value="Monthly">Monthly</Radio>
                  <Radio value="Weekly">Weekly</Radio>
                  <Radio value="Quarterly">Quarterly</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onRecurringModalClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                createPlan();
              }}
            >
              Create Recurring Plan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Pack Modal*/}
      <Modal size={'xl'} isOpen={isPackModalOpen} onClose={onPackModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create pack</ModalHeader>
          <Separator />
          <ModalCloseButton />
          <ModalBody>
            <FormControl marginBottom="10px" marginTop="20px">
              <FormLabel>Plan name</FormLabel>
              <Input
                ref={initialRef}
                placeholder="Plan name"
                value={planName}
                onChange={handlePlanNameChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Price</FormLabel>
              <NumberInput
                precision={2}
                onChange={valueString => setPrice(parse(valueString))}
                value={format(price)}
                max={1000}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl marginTop="20px">
              <FormLabel>Pack size</FormLabel>

              <Flex>
                <NumberInput
                  maxW="100px"
                  mr="2rem"
                  value={sliderValue}
                  onChange={handleSliderChange}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Slider
                  flex="1"
                  focusThumbOnChange={false}
                  value={sliderValue}
                  onChange={handleSliderChange}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb
                    fontSize="sm"
                    color="black"
                    boxSize="32px"
                    children={sliderValue}
                  />
                </Slider>
              </Flex>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onPackModalClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                createPlan();
              }}
            >
              Create Pack
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Prepaid Modal*/}
      <Modal
        size={'xl'}
        isOpen={isPrepaidModalOpen}
        onClose={onPrepaidModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create prepaid plan</ModalHeader>
          <Separator />
          <ModalCloseButton />
          <ModalBody>
            <FormControl marginBottom="10px" marginTop="20px">
              <FormLabel>Plan name</FormLabel>
              <Input
                ref={initialRef}
                placeholder="Plan name"
                value={planName}
                onChange={handlePlanNameChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Price</FormLabel>
              <NumberInput
                precision={2}
                onChange={valueString => setPrice(parse(valueString))}
                value={format(price)}
                max={1000}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl marginTop="20px" marginBottom="50px">
              <FormLabel>Valid for</FormLabel>

              <NumberInput
                step={1}
                defaultValue={7}
                min={1}
                max={31}
                value={validFor}
                onChange={handleValidForChange}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <RadioGroup
                marginTop="20px"
                onChange={setPrepaidRecurrenceValue}
                value={prepaidRecurrenceValue}
              >
                <Stack direction="row">
                  <Radio value="Days">Days</Radio>
                  <Radio value="Weeks">Weeks</Radio>
                  <Radio value="Months">Months</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onPrepaidModalClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                createPlan();
              }}
            >
              Create Prepaid Plan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Plans);
