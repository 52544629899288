// Chakra imports
import {
  useColorModeValue,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card';
import ProfileInformation from './ProfileInformation';
import PlanInformation from "./PlanInformation";
import React, {useState} from 'react';

const MemberManager = ({
  name,
  phone,
  email,
  address,
  dateJoined,
  dob,
  emergencyPhone,
  planName,
  id,
  member
}) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');
  const [trigger, setTrigger] = useState(0);
  return (
    <Card p="16px" w={{ sm: '98%', xl: '98%' }}>
      <Tabs onChange={(index) => setTrigger(index)} isFitted colorScheme="teal">
        <TabList>
          <Tab _focus={{ boxShadow: "none", }} fontWeight="bold">Profile</Tab>
          <Tab _focus={{ boxShadow: "none", }} fontWeight="bold">Plans</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ProfileInformation
              name={name}
              phone={phone}
              email={email}
              address={address}
              dateJoined={dateJoined}
              dob={dob}
              emergencyPhone={emergencyPhone}
            />
          </TabPanel>
          <TabPanel>
          <PlanInformation
              member={member}
              trigger={trigger}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default MemberManager;
