import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  LinkBox,
  Link
} from "@chakra-ui/react";
import React from "react";
import { useParams, useHistory } from 'react-router-dom';


function MembersRow(props) {
  const history = useHistory();
  const { photo, name, email, status, date, member } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const link = `/admin/member/${member.id}`

  return (
    <LinkBox as={Tr}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Link onClick={() => history.push(link)}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={photo} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {email}
            </Text>
          </Flex>
        </Flex>
        </Link>
      </Td>

      <Td>
        <Badge
          bg={status === "Active" ? "green.400" : bgStatus}
          color={status === "Active" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
      </Td>
      <Td>
      </Td>
    </LinkBox>
  );
}

export default MembersRow;
