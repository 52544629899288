// Chakra imports
import { Flex, Icon, Link, Text, useColorModeValue, Button, Center } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";
import { EditIcon } from '@chakra-ui/icons';


const ProfileInformation = ({
  name,
  phone,
  email,
  address,
  dateJoined,
  dob,
  emergencyPhone
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  // const onSaveEdit = async (id, plan, name) => {
  //   const docRef = await doc(db, 'members', id);
  //   data = {
  //     plan: plan,
  //     fullname: name
  //   };
  //   updateDoc(docRef, data)
  //     .then(() => {
  //       console.log('Entire Document has been updated successfully.');
  //       onCloseEdit();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      {/* <Center>
        <Button w='40%' mb='30px' leftIcon={<EditIcon />}>
          Edit
        </Button>
        </Center> */}
      <CardBody px='5px'>
        <Flex direction='column'>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Full name:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {name}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Email:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {email}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Phone:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {phone}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Address:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {address}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Date joined:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {dateJoined}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Date of birth:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {dob}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Emergency contact phone:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {emergencyPhone}
            </Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileInformation;
