// Chakra imports
import { Button, Flex, Text, Icon } from '@chakra-ui/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { useHistory, useParams } from 'react-router-dom';


const CheckoutForm = ({ member, membership, token, memberId }) => {
  const history = useHistory();
  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  const { id } = useParams();

  // main function
  const createSubscription = async () => {
    try {
      // create a payment method
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: member.fullname,
          email: member.email,
        },
      });

      //   call the backend to create subscription
      const response = await fetch('/api/create-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          paymentMethod: paymentMethod.paymentMethod.id,
          name: member.fullname,
          email: member.email,
          priceId: 'price_1MilmoHf24nT1qEHfn96PJx1',
        }),
      }).then(res => res.json());

      const confirmPayment = await stripe.confirmCardPayment(
        response.clientSecret
      );

      if (confirmPayment.error) {
        alert(confirmPayment.error.message);
      } else {
        updateMembership();
      }
    } catch (error) {
      console.log(error);
    }
  };

    const updateMembership = async () => {
    const docRef = await doc(db, 'memberships', id);
    const data = {
      isActive: true,
    };
    updateDoc(docRef, data)
      .then(() => {
        console.log('Entire Document has been updated successfully.');
        history.push(`/admin/member/${memberId}`)
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      <div style={containerStyles} className="grid gap-4 m-auto">
        <CardElement options={cardElementOptions} />
      </div>
      <Flex height="30px" marginTop="20px" marginBottom="10px">
        <Flex direction="row" justifyContent="center" align="center">
          <Icon as={FaLock} mr="5px" fontSize="lg" color="gray.600" />
          <Text as="i" color="gray.600" fontSize="md">
            Secure connection - All data transmitted will be secure.
          </Text>
        </Flex>
      </Flex>
      <Button marginTop="10px" onClick={createSubscription} disabled={!stripe}>
        Activate plan
      </Button>
    </div>
  );
};

const cardElementOptions = {
  style: {
    base: {
      color: 'white',
      fontSize: '18px',
    },
    invalid: {
      color: '#fa755a',
      fontSize: '20px',
    },
  },
};

const containerStyles = {
  border: '1px solid #5a94c0',
  padding: '5px 10px 5px 10px',
  borderRadius: '4px',
  marginTop: '10px',
  marginBottom: '10px',
  width: '100%',
};

export default CheckoutForm;
