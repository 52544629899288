// Chakra imports
import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import avatar4 from 'assets/img/avatars/avatar4.png';
import ProfileBgImage from 'assets/img/ProfileBackground.png';
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import MemberManager from './components/MemberManager';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import { PersonIcon } from 'components/Icons/Icons.js';
import { EditIcon } from '@chakra-ui/icons';

import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';

function Member() {
  const [data, setData] = useState([]);
  const [user, loading] = useAuthState(auth);

  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
  );

  const { id } = useParams();

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchMember();
    }
  }, [user, loading]);

  const fetchMember = async () => {
    const docRef = doc(db, 'members', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData(docSnap.data());
    } else {
      setData(undefined);
      console.log('No document!');
    }
  };

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={data.profilePic}
        name={data.fullname}
        email={data.email}
        tabs={[
          {
            name: 'Profile',
            icon: <PersonIcon w="20px" />,
          },
          {
            name: 'Plans',
            icon: <EditIcon w="20px" />,
          },
        ]}
      />
      <Grid marginTop="20px">
        <MemberManager
          title={'Platform Settings'}
          name={data.fullname}
          phone={data.phone}
          email={data.email}
          address={data.address}
          dateJoined={data.dateAdded}
          dob={data.dob}
          emergencyPhone={data.emergencyPhone}
          planName={data.plan}
          id={data.id}
          member={data}
        />
      </Grid>
    </Flex>
  );
}

export default Member;
