import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  LinkBox,
  Link,
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  Stack,
  Radio,
  RadioGroup,
  EditablePreview,
  Box,
  Divider,
  IconButton,
  Input,
  useDisclosure,
  useEditableControls,
  ButtonGroup,
  SlideFade,
  Editable,
  Tooltip,
  EditableInput,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(
  'pk_test_51MilkGHf24nT1qEHi1NnHEbd8aYE9lGfSYccKf7KVZxBrpjfhkdzKXm4YghuCM749XNqSJlAP7kgXRvHQpClthc700n7kHqm57'
);

const RecurringMembership = ({ member, membership, token, memberId }) => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const now = new Date();
  const [expirationDate, setExpirationDate] = useState(
    new Date(now.getFullYear(), now.getMonth() + 1, 1)
  );
  const [radioValue, setRadioValue] = useState('none');
  const [paymentRadioValue, setPaymentRadioValue] = useState('card');
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
        <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton
          icon={<CloseIcon boxSize={3} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : null;
  }

  return (
    <Flex direction="column">
      <TableContainer>
        <Table size="md" maxWidth="700px">
          <Tbody>
            <Tr>
              <Td>Start date:</Td>
              <Td>
                <Flex width="180px">
                  <SingleDatepicker
                    name="date-input"
                    date={startDate}
                    onDateChange={setStartDate}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'blue',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'blue.400',
                          },
                        },
                        selectedBtnProps: {
                          background: 'blue.200',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.600',
                        },
                      },
                      popoverCompProps: {
                        popoverContentProps: {
                          background: 'gray.700',
                          color: 'white',
                        },
                      },
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td>Expiration date:</Td>
              <Td>
                <Stack>
                  <RadioGroup onChange={setRadioValue} value={radioValue}>
                    <Stack direction="column">
                      <Radio value="none">None (no expiration)</Radio>
                      <Radio value="specific">Specific date</Radio>
                    </Stack>
                  </RadioGroup>
                  <Flex width="180px">
                    <SingleDatepicker
                      name="date-input"
                      date={expirationDate}
                      onDateChange={setExpirationDate}
                      propsConfigs={{
                        dateNavBtnProps: {
                          colorScheme: 'blue',
                          variant: 'outline',
                        },
                        dayOfMonthBtnProps: {
                          defaultBtnProps: {
                            _hover: {
                              background: 'blue.400',
                            },
                          },
                          selectedBtnProps: {
                            background: 'blue.200',
                            color: 'white',
                          },
                          todayBtnProps: {
                            background: 'gray.600',
                          },
                        },
                        popoverCompProps: {
                          popoverContentProps: {
                            background: 'gray.700',
                            color: 'white',
                          },
                        },
                      }}
                    />
                  </Flex>
                </Stack>
              </Td>
            </Tr>
            <Tr>
              <Td>Recurring payment:</Td>

              <Td>
                <Flex width="180px">
                  <Editable
                    defaultValue={'$20.00'}
                    isPreviewFocusable={true}
                    selectAllOnFocus={false}
                  >
                    <Stack direction="column">
                      <Tooltip label="Click to edit" placement="right-end">
                        <EditablePreview
                          py={2}
                          px={4}
                          _hover={{
                            background: useColorModeValue(
                              'gray.100',
                              'gray.700'
                            ),
                          }}
                        />
                      </Tooltip>
                      <Input py={2} px={4} as={EditableInput} />
                      <EditableControls />
                    </Stack>
                  </Editable>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td>Payment option:</Td>
              <Td>
                <RadioGroup
                  onChange={setPaymentRadioValue}
                  value={paymentRadioValue}
                >
                  <Stack direction="column">
                    <Radio value="card">Credit/Debit</Radio>
                    <Radio value="cash">Cash</Radio>
                    <Elements stripe={stripePromise}>
                      <CheckoutForm member={member} membership={membership} token={token} memberId={memberId} />
                    </Elements>
                  </Stack>
                </RadioGroup>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default RecurringMembership;
