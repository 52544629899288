import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
  } from "@chakra-ui/react";
  import React from "react";
  import { deleteDoc, collection, doc } from 'firebase/firestore';
  import { db } from '../../../../firebase';
  import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
  
  function PrepaidPlanRow(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("#F8F9FA", "gray.800");
    const nameColor = useColorModeValue("gray.500", "white");
    const { name, type, price, validFor, validType, createdAt, planId } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const deletePlan = async id => {
      const docRef = await doc(db, 'plans', id);
      deleteDoc(docRef)
        .then(() => {
          console.log('Entire Document has been deleted successfully.');
          onClose();
        })
        .catch(error => {
          console.log(error);
        });
    };
  
    return (
      <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
        <Flex justify="space-between" w="100%">
          <Flex direction="column" maxWidth="70%">
            <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
              {name}
            </Text>
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Plan type:{" "}
              <Text as="span" color="gray.500">
                {type}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Price:{" "}
              <Text as="span" color="gray.500">
                {price}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Valid for:{" "}
              <Text as="span" color="gray.500">
                {validFor}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Created:{" "}
              <Text as="span" color="gray.500">
                {createdAt}
              </Text>
            </Text>
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            align="flex-start"
            p={{ md: "24px" }}
          >
            <Button
              onClick={onOpen}
              p="0px"
              bg="transparent"
              mb={{ sm: "10px", md: "0px" }}
              me={{ md: "12px" }}
            >
              <Flex color="red.500" cursor="pointer" align="center" p="12px">
                <Icon as={FaTrashAlt} me="4px" />
                <Text fontSize="sm" fontWeight="semibold">
                  DELETE
                </Text>
              </Flex>
            </Button>
            <Button p="0px" bg="transparent">
              <Flex color={textColor} cursor="pointer" align="center" p="12px">
                <Icon as={FaPencilAlt} me="4px" />
                <Text fontSize="sm" fontWeight="semibold">
                  EDIT
                </Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>

        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Plan
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this plan? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => deletePlan(planId)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Box>
    );
  }
  
  export default PrepaidPlanRow;
