// Chakra imports
import {
  Flex,
  Grid,
  useColorModeValue,
  Text
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import avatar4 from 'assets/img/avatars/avatar4.png';
import ProfileBgImage from 'assets/img/ProfileBackground.png';
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import MemberManager from './components/MemberManager';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import { getIdToken } from "firebase/auth"
import { PersonIcon } from 'components/Icons/Icons.js';
import { EditIcon } from '@chakra-ui/icons';
import RecurringMembership from "./components/RecurringMembership";
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';

function Membership() {
  const [data, setData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [user, loading] = useAuthState(auth);
  const [firebaseToken, setFirebaseToken] = useState("");

  const { id } = useParams();

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchMembership();
      getFirebaseToken();
    }
  }, [user, loading]);

  const getFirebaseToken = async () => {
    const token = await getIdToken(user, true)
    setFirebaseToken(token)
  }

  const fetchMembership = async () => {
    const docRef = doc(db, 'memberships', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData(docSnap.data());
      const memberId = docSnap.data().memberId
      setMemberId(memberId)
      fetchMember(memberId);
    } else {
      setData(undefined);
      console.log('No Membership document!');
    }
  };

  const fetchMember = async (memberId) => {
    const docRef = doc(db, 'members', memberId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setMemberData(docSnap.data());
    } else {
      setMemberData(undefined);
      console.log('No Member document!');
    }
  };

  return (
    <Flex direction="column" mt="55px">
      <Grid marginTop="20px">
        <Card minH="350px" p="16px" w={{ sm: '98%', xl: '98%' }}>
        <CardHeader p="6px 0px 22px 0px">
        <Flex direction='column'>
        <Text fontSize="xl" color='white' fontWeight="bold">
          Activate plan for {memberData.fullname}
        </Text>
        <Text fontSize='sm' color='gray.500' fontWeight='400'>
            Plan name: {data.planName}
          </Text>
          </Flex>
      </CardHeader>

      <RecurringMembership member={memberData} membership={data} token={firebaseToken} memberId={memberId} />
        </Card>
      </Grid>
    </Flex>
  );
}

export default Membership;
