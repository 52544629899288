// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import Members from "./components/Members";
import { tablesTableData, dashboardTableData } from "variables/general";

function MembersTables() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Members
        title={"Members"}
        captions={["Member", "Status", "Date added", ""]}
        data={tablesTableData}
      />
    </Flex>
  );
}

export default MembersTables;
