// Chakra imports
import {
  Flex,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Center,
  FormLabel,
  Select,
  ModalFooter,
  useDisclosure,
  Box,
  Icon,
  Switch,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
  Popover,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { AddIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";

import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
  onSnapshot
} from 'firebase/firestore';
import { auth, db } from '../../../../firebase';
import { connect, useSelector } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { v4 as uuidv4 } from 'uuid';

const PlanInformation = ({
  member,
  trigger
}) => {
  // Chakra color mode
  const bgColor = useColorModeValue("#F8F9FA", "gray.800");
  const textColor = useColorModeValue("gray.700", "white");
  const nameColor = useColorModeValue("gray.500", "white");

  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const cancelRef = React.useRef();
  const initialFocusRef = React.useRef();
  const [selectValue, setSelectValue] = useState('');
  const [planData, setPlanData] = useState([]);
  const [membershipsData, setMembershipsData] = useState([]);
  const [user, loading] = useAuthState(auth);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchPlans();
      if (trigger == 1) {
        fetchMemberships()
      }
    }
  }, [user, loading, trigger]);

  const fetchPlans = async () => {
    const q = query(collection(db, 'plans'), where('clientId', '==', user.uid));
    const data = await getDocs(q);
    const planData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setPlanData(planData);
    setSelectValue(planData[0].name);
  };

  // const fetchMemberships = async () => {
  //   const q = query(collection(db, 'memberships'), where('memberGuid', '==', member.guid));
  //   const data = await getDocs(q);
  //   const membershipsData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  //   setMembershipsData(membershipsData);
  // }

  const fetchMemberships = async () => {
    const q = query(collection(db, 'memberships'), where('memberGuid', '==', member.guid));
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let membershipsArr = [];
      querySnapshot.forEach(doc => {
        membershipsArr.push({ ...doc.data(), id: doc.id });
      });
      setMembershipsData(membershipsArr);
    });

    return () => unsubscribe();
  };

  const createPlan = async (planName, planId) => {
    try {
      const today = new Date().toISOString().slice(0, 10);
      const guid = 'MBS-' + uuidv4();
      const doc = await addDoc(collection(db, `memberships`), {
        dateAdded: today,
        guid,
        memberGuid: member.guid,
        planId,
        planName,
        memberId: id,
        isActive: false
      })
      history.push(`/admin/membership/${doc.id}`)
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

  const onSwitch = async (membership) => {
    membership.isActive ? setupBilling() : history.push(`/admin/membership/${membership.id}`)
    // alert(member.guid)
    // alert(membership.guid)
  }

  return (
      //   <Box p="24px" bg={bgColor} mt="30px" borderRadius="12px">
      //   <Flex justify="space-between" w="100%">
      //     <Flex
      //       direction={{ sm: "column", md: "row" }}
      //       align="flex-start"
      //       p={{ md: "24px" }}
      //     >
      //       <Flex
      //         p="0px"
      //         bg="transparent"
      //         mb={{ sm: "10px", md: "0px" }}
      //         me={{ md: "12px" }}
      //       >
      //         <Flex align="center" p="12px">
      //           <CheckCircleIcon me="4px" />
      //           <Text fontSize="md" fontWeight="semibold">
      //             Active plan: Test
      //           </Text>
      //         </Flex>
      //       </Flex>
      //       <Button
      //         onClick={onOpen}
      //         p="0px"
      //         bg="transparent"
      //         mb={{ sm: "10px", md: "0px" }}
      //         me={{ md: "12px" }}
      //       >
      //         <Flex color="red.500" cursor="pointer" align="center" p="12px">
      //           <Icon as={FaTrashAlt} me="4px" />
      //           <Text fontSize="sm" fontWeight="semibold">
      //             Cancel
      //           </Text>
      //         </Flex>
      //       </Button>
      //     </Flex>
      //   </Flex>
      //   <AlertDialog
      //   isOpen={isOpen}
      //   leastDestructiveRef={cancelRef}
      //   onClose={onClose}
      // >
      //   <AlertDialogOverlay>
      //     <AlertDialogContent>
      //       <AlertDialogHeader fontSize='lg' fontWeight='bold'>
      //         Delete Plan
      //       </AlertDialogHeader>

      //       <AlertDialogBody>
      //         Are you sure you want to delete this plan? You can't undo this action afterwards.
      //       </AlertDialogBody>

      //       <AlertDialogFooter>
      //         <Button ref={cancelRef} onClick={onClose}>
      //           Cancel
      //         </Button>
      //         <Button colorScheme='red' onClick={() => deletePlan(planId)} ml={3}>
      //           Delete
      //         </Button>
      //       </AlertDialogFooter>
      //     </AlertDialogContent>
      //   </AlertDialogOverlay>
      // </AlertDialog>

      // <Modal
      //   initialFocusRef={initialRef}
      //   finalFocusRef={finalRef}
      //   isOpen={isOpen}
      //   onClose={onClose}
      // >
      //   <ModalOverlay />
      //   <ModalContent>
      //     <ModalHeader>Add plan</ModalHeader>
      //     <ModalCloseButton />
      //     <ModalBody pb={6}>
      //       <FormControl>
      //         <FormLabel>Plan (select one)</FormLabel>
      //         <Select
      //           mt={4}
      //           value={selectValue}
      //           onChange={e => setSelectValue(e.target.value)}
      //         >
      //           {planData &&
      //             planData.map(plan => {
      //               console.log(plan);
      //               return <option value={plan.name}>{plan.name}</option>;
      //             })}
      //         </Select>
      //       </FormControl>
      //     </ModalBody>

      //     <ModalFooter>
      //       <Button
      //         // onClick={() => onSaveEdit(memberId, selectValue, memberName)}
      //         colorScheme="blue"
      //         mr={3}
      //       >
      //         Save
      //       </Button>
      //       <Button onClick={onClose}>Cancel</Button>
      //     </ModalFooter>
      //   </ModalContent>
      // </Modal>
      // </Box>

      <Card minH='300px' p='16px'>
        <Center>
        <Popover
            initialFocusRef={initialFocusRef}
            placement="bottom"
            closeOnBlur={true}
          >
            <PopoverTrigger>
            <Button w='30%' mb='30px' leftIcon={<AddIcon />}>
          Add plan
        </Button>
            </PopoverTrigger>
            <PopoverContent color="white" bg="gray.700" borderColor="blue.800">
              <PopoverArrow />
              <PopoverBody>
                <FormControl>
                  <Select
                mt={10}
                value={selectValue}
                onChange={e => setSelectValue(e.target.value)}
              >
                {planData &&
                  planData.map(plan => {
                    return <option value={plan.name}>{plan.name}</option>;
                  })}
              </Select>
                </FormControl>
              </PopoverBody>
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Box fontSize="md"></Box>
                <ButtonGroup size="sm">
                  <Button onClick={() => createPlan(selectValue, 123)} color="white">
                    Next
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Center>
      <CardBody px='5px'>

        <Flex direction='column'>
          <Text fontSize='lg' color='gray.400' fontWeight='600' mb='20px'>
            Jordan's plans
          </Text>
          <Flex direction='column' align='center' mb='20px'>
            {membershipsData && membershipsData.map(membership => {
              return(
                <Flex margin='10px'>
                <Switch onChange={() => onSwitch(membership)} isChecked={membership.isActive} colorScheme='teal' me='10px' size='lg' />
                <Text noOfLines={1} fontSize='md' color='white' fontWeight='400'>
              {membership.planName}
            </Text>
            </Flex>
              )
            })}
          </Flex>
        </Flex>
      </CardBody>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Plan (select one)</FormLabel>
              <Select
                mt={4}
                value={selectValue}
                onChange={e => setSelectValue(e.target.value)}
              >
                {planData &&
                  planData.map(plan => {
                    return <option value={plan.name}>{plan.name}</option>;
                  })}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => createPlan(selectValue, 123)}
              colorScheme="blue"
              mr={3}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
      <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Plan
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this plan? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => deletePlan(planId)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PlanInformation);