// Chakra imports
import { Flex, Text, useColorModeValue, Skeleton, Stack } from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import RecurringPlanRow from './RecurringPlanRow';
import PackPlanRow from './PackPlanRow';
import PrepaidPlanRow from './PrepaidPlanRow';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { db } from '../../../../firebase';
import { query, getDocs, collection, where, addDoc, onSnapshot } from 'firebase/firestore';

const PlanManager = ({ data }) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const auth = useSelector(state => state.auth);
  const [plans, setPlans] = useState([]);
  const [noPlansIndicator, setNoPlansIndicator] = useState('none');
  const [displaySkeleton, setDisplaySkeleton] = useState('block')


  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    const q = query(
      collection(db, 'plans'),
      where('clientGuid', '==', auth.user.guid)
    );
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let plansArr = [];
      querySnapshot.forEach(doc => {
        plansArr.push({ ...doc.data(), id: doc.id });
      });
      setPlans(plansArr);
      if (plansArr.length > 0) {
        setNoPlansIndicator('none');
      } else {
        setNoPlansIndicator('block');
      }
    });
    setDisplaySkeleton('none');

    return () => unsubscribe();
  };

  return (
    <Card my={{ lg: '24px' }} me={{ lg: '24px' }}>
      <Flex direction="column">
        <CardHeader py="12px">
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Existing plans
          </Text>
        </CardHeader>
        <Text
          display={noPlansIndicator}
          fontSize="md"
          color="gray.500"
          fontWeight="400"
          mb="20px"
        >
          {' '}
          You haven't created any plans yet. Click the button above to get
          started.{' '}
        </Text>
        <CardBody>
          <Flex direction="column" w="100%">
            <Stack>
              <Skeleton startColor='gray.700' endColor='gray.800' display={displaySkeleton} height="120px" />
              <Skeleton startColor='gray.700' endColor='gray.800' display={displaySkeleton} height="120px" />
              <Skeleton startColor='gray.700' endColor='gray.800' display={displaySkeleton} height="120px" />
            </Stack>
            {plans.map(plan => {
              if (plan.type == 'Recurring') {
                return (
                  <RecurringPlanRow
                    key={`${plan.id}`}
                    name={plan.name}
                    type={plan.type}
                    price={plan.price}
                    recurrence={plan.recurrence}
                    createdAt={plan.createdAt}
                    planId={plan.id}
                  />
                );
              }
              if (plan.type == 'Pack') {
                return (
                  <PackPlanRow
                    key={`${plan.id}`}
                    name={plan.name}
                    type={plan.type}
                    price={plan.price}
                    size={plan.packSize}
                    createdAt={plan.createdAt}
                    planId={plan.id}
                  />
                );
              }
              if (plan.type == 'Prepaid') {
                return (
                  <PrepaidPlanRow
                    key={`${plan.id}`}
                    name={plan.name}
                    type={plan.type}
                    price={plan.price}
                    validFor={`${plan.validFor} ${plan.validForType}`}
                    createdAt={plan.createdAt}
                    planId={plan.id}
                  />
                );
              }
            })}
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PlanManager);
